import React from "react";
import styles from "./Input.module.css";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { DeepMap, FieldError, UseFormRegister } from "react-hook-form";

interface InputProps extends ReturnType<UseFormRegister<any>> {
  title?: string;
  name: string;
  type?: string;
  disabled?: boolean;
  inline?: boolean;
  placeholder?: string;
  errors?: DeepMap<any, FieldError>;
  readOnly?: boolean;
  defaultValue?: string | null;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    return (
      <div>
        <div className={props.inline ? styles.Input___inline : ""}>
          {props.title && (
            <label className={styles.Input_label}>{props.title}</label>
          )}
          <input
            className={styles.Input}
            name={props.name}
            onChange={props.onChange}
            onBlur={props.onBlur}
            disabled={props.disabled}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue || ""}
            type={props.type}
            readOnly={props.readOnly}
            ref={ref}
          />
          <ErrorMessage name={props.name} errors={props.errors} />
        </div>
      </div>
    );
  }
);
