import styles from "./Layout.module.css";
import { AlertModal } from "../UI/AlertModal/AlertModal";
import { NavBar } from "../Navigation/NavBar";

const Layout: React.FC = (props) => {
  return (
    <AlertModal>
      <div className={styles.Main}>
        <NavBar />
        <main className={styles.Main_content}>{props.children}</main>
      </div>
    </AlertModal>
  );
};

export default Layout;
