import { useState, useEffect, useCallback } from "react";

const getWindowDimensions = () => {
  const { innerWidth: width } = window;
  return width <= 600;
};

export const useWindowDimensions = () => {
  const [mobile, setMobile] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setMobile(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return mobile;
};

export const useToggle = (initialState: boolean = false): [boolean, any] => {
  // Initialize the state
  const [state, setState] = useState<boolean>(initialState);
  // Define and memorize toggler function in case we pass down the comopnent,
  // This function change the boolean value to it's opposite value
  const toggle = useCallback(
    (def?: boolean): void =>
      setState((state) => {
        if (typeof def === "boolean") return def;
        return !state;
      }),
    []
  );
  return [state, toggle];
};
