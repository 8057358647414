import styles from "../Navigation.module.css";
import { FaCaretDown } from "react-icons/fa";
import { useToggle } from "../../../util/hooks";
import React from "react";

interface DropdownItemProps {
  title: string;
}

export const DropdownItem: React.FC<DropdownItemProps> = (props) => {
  const [open, toggleOpen] = useToggle();

  return (
    <div className={styles.dropdown}>
      <button
        type="button"
        className={[styles.dropbtn, open ? styles.dropbtn___open : ""].join(
          " "
        )}
        onClick={() => {
          toggleOpen();
          setTimeout(() => toggleOpen(false), 3000);
        }}
      >
        {props.title}
        <FaCaretDown />
      </button>
      <div
        className={[styles.content, open ? styles.content___open : ""].join(
          " "
        )}
      >
        {props.children}
      </div>
    </div>
  );
};
