import React from "react";
import { DeepMap, FieldError, UseFormRegister } from "react-hook-form";
import styles from "./Select.module.css";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

export type Options = {
  value: number | string;
  text: string;
};

interface SelectProps extends ReturnType<UseFormRegister<any>> {
  options: Options[];
  title: string;
  defaultValue?: string;
  disabled?: boolean;
  errors?: DeepMap<any, FieldError>;
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    if (props.options.length === 0) {
      return (
        <div className={styles.SelectWrapper}>
          {props.title && (
            <label className={styles.Input_label}>{props.title}</label>
          )}
          <select
            className={styles.Select}
            disabled
            name={props.name}
            onChange={props.onChange}
            ref={ref}
          ></select>

          <ErrorMessage>No se encontraron valores</ErrorMessage>
        </div>
      );
    }
    return (
      <div className={styles.SelectWrapper}>
        {props.title && (
          <label className={styles.Input_label}>{props.title}</label>
        )}
        <select
          className={styles.Select}
          disabled={props.disabled}
          name={props.name}
          onChange={props.onChange}
          ref={ref}
        >
          {props.defaultValue ? (
            <option hidden disabled value="">
              {props.defaultValue}
            </option>
          ) : null}
          {props.options.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.text}
            </option>
          ))}
        </select>

        <ErrorMessage name={props.name} errors={props.errors} />
      </div>
    );
  }
);
