import React, { Suspense, useEffect } from "react";
import SignIn from "./components/Auth/SignIn/SignIn";
import Layout from "./components/Layout/Layout";

import { Route, Switch, Redirect } from "react-router-dom";
import { Spinner } from "./components/UI/Spinner/Spinner";
import { authCheckState } from "./store/authSlice";
import { useAppDispatch, useAppSelector } from "./store/store";

const Creacion = React.lazy(() => {
  return import("./components/Creador/Creador");
});
const Usuario = React.lazy(() => import("./components/Usuario/Usuario"));
const ForgotPassword = React.lazy(
  () => import("./components/Auth/Recover/ForgotPassword")
);

const Examen = React.lazy(() => import("./components/Examen/Examen"));

const Disposiciones = React.lazy(
  () => import("./components/Disposiciones/Disposiciones")
);

const TablaDisposiciones = React.lazy(
  () => import("./components/Disposiciones/Tabla/TablaDisposiciones")
);

const Administracion = React.lazy(
  () => import("./components/Administracion/Administracion")
);

const GafiRecomendaciones = React.lazy(
  () => import("./components/Otros/GafiRecomendaciones/GafiRecomendaciones")
);

export const App = () => {
  const dispatch = useAppDispatch();
  const { token, isAdmin } = useAppSelector((state) => state.auth);
  const isAuthenticated = token !== null;

  useEffect(() => {
    dispatch(authCheckState());
  }, [dispatch]);

  let routes = (
    <>
      <Route path="/auth" render={() => <SignIn />} />
      <Route path="/forgotPassword" component={ForgotPassword} />
      <Redirect from="*" to="/auth" />
    </>
  );
  if (isAuthenticated) {
    routes = (
      <>
        <Route path="/" exact component={Usuario} />
        <Route path="/examen" component={Examen} />
        <Route path="/disposiciones" component={Disposiciones} />
        <Route path="/tabladisposiciones" component={TablaDisposiciones} />
        <Route path="/recomendaciones" component={GafiRecomendaciones} />
        {isAdmin ? (
          <>
            <Route path="/creacion" component={Creacion} />
            <Route path="/administracion" component={Administracion} />
          </>
        ) : null}
        <Redirect from="*" to="/" />
      </>
    );
  }

  return (
    <Layout>
      <Suspense fallback={<Spinner />}>
        <Switch>{routes}</Switch>
      </Suspense>
    </Layout>
  );
};
