import styles from "./Navigation.module.css";
import { NavBarItems } from "./NavBarItems/NavBarItems";
import TestLogo from "../../assets/images/3C.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { useToggle, useWindowDimensions } from "../../util/hooks";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { fetchCursos } from "../../util/fetchRequest";
import { useQuery } from "react-query";
import { Button } from "../UI/Button/Button";
import { Modal } from "../UI/Modal/Modal";
import { setCurso } from "../../store/authSlice";
import { useLocation } from "react-router";

interface BackdropProps {
  show: boolean;
  clicked: () => void;
}

const Backdrop: React.FC<BackdropProps> = (props) => {
  return props.show ? (
    <div className={styles.Backdrop} onClick={props.clicked}>
      {props.children}
    </div>
  ) : null;
};

export const NavBar: React.FC = (props) => {
  const location = useLocation();
  const mobile = useWindowDimensions();
  const [showDrawer, toggleDrawer] = useToggle();
  const [openModal, setOpenModal] = useToggle();
  const { isAdmin } = useAppSelector((state) => state.auth);
  const { data: dbCursos } = useQuery("fetchCursos", fetchCursos);

  const path = location.pathname;

  const dispatch = useAppDispatch();

  const Icon = showDrawer ? FaTimes : FaBars;

  const cursos = dbCursos?.cursos || [];

  const cursosOptions = [
    { value: "admin", text: "Admin" },
    ...cursos.map((c) => ({ value: c.id, text: c.descripcion })),
  ];

  return (
    <header className={styles.header}>
      <Modal
        title="Cambiar curso"
        onHide={() => setOpenModal(false)}
        show={openModal}
      >
        <div className={styles.list_cursos}>
          {cursosOptions.map((c) => (
            <div
              key={c.value}
              className={styles.list_cursos_item}
              onClick={() => {
                dispatch(setCurso(c.value));
                setOpenModal(false);
              }}
            >
              {c.text}
            </div>
          ))}
        </div>
      </Modal>
      {mobile ? (
        <>
          <ul className={styles.nav_header}>
            <Link to="/">
              <img
                className={styles.logo_img}
                height="40"
                width="40"
                alt="Evaluaciones"
                src={TestLogo}
              />
            </Link>

            {isAdmin && path === "/" ? (
              <div style={{ paddingLeft: "0.5em", width: "10em" }}>
                <Button onClick={setOpenModal} clear>
                  Cambiar curso
                </Button>
              </div>
            ) : null}

            <div className={styles.menu} onClick={toggleDrawer}>
              <Icon />
            </div>
          </ul>

          <div
            className={[
              styles.SideDrawer,
              showDrawer ? styles.SideDrawer___Open : "",
            ].join(" ")}
          >
            <nav>
              <NavBarItems menuHandler={toggleDrawer} />
            </nav>
          </div>
          <Backdrop show={showDrawer} clicked={toggleDrawer} />
        </>
      ) : (
        <ul className={styles.nav_header}>
          <Link className={styles.img_li_a} to="/">
            <img
              className={styles.logo_img}
              height="40"
              width="40"
              alt="Evaluaciones"
              src={TestLogo}
            />
          </Link>
          {isAdmin && path === "/" ? (
            <div style={{ paddingLeft: "0.5em", width: "10em" }}>
              <Button onClick={setOpenModal} clear>
                Cambiar curso
              </Button>
            </div>
          ) : null}
          <nav className={styles.nav}>
            <NavBarItems menuHandler={toggleDrawer} />
          </nav>
        </ul>
      )}
    </header>
  );
};
