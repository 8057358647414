import {
  SujetosObligados,
  Cursos,
  Integraciones,
  Disposiciones,
  DisposicionesWithSujetos,
  Modulos,
  Claves,
  Preguntas,
  Usuarios,
  GafiRecomendacionesType,
  DetalladoCursos,
  Resultados,
} from "./models";
import { server } from "./axios";

export const fetchModulos = async () => {
  const response = await server.get<{ modulos: Modulos[] }>("/examen/modulos");
  return response.data.modulos;
};

export const fetchCursos = async () => {
  const response = await server.get<{
    cursos: Cursos[];
    detalladoCursos: DetalladoCursos[];
  }>("/admin/cursos");

  const cursos = response.data.cursos;
  const detalladoCursos = response.data.detalladoCursos;

  return { cursos, detalladoCursos };
};

export const fetchClaves = async (curso: "admin" | number) => {
  const urlClaves =
    curso === "admin" ? "/examen/claves" : `/examen/claves/${curso}`;
  const response = await server.get<{ claves: Claves[] }>(urlClaves);
  return response.data.claves;
};

export const fetchPreguntas = async (clave: string) => {
  const responsePreguntas = await server.get<{ preguntas: Preguntas[] }>(
    `/examen/preguntas/${clave}`
  );
  return responsePreguntas.data.preguntas;
};

export const fetchSujetosObligados = async () => {
  const response = await server.get<{
    sujetosObligados: SujetosObligados[];
  }>("/examen/sujetos");
  return response.data.sujetosObligados;
};

export const fetchIntegracionesCCC = async () => {
  const response = await server.get<{
    integraciones: Integraciones[];
  }>("/examen/integraciones");
  return response.data.integraciones;
};

export const fetchDisposiciones = async (
  sujetoSeleccionado: number | null,
  integracionCCC: number | null
) => {
  if (sujetoSeleccionado) {
    const response = await server.get<{
      disposiciones: Disposiciones[];
    }>("/examen/disposicion/" + sujetoSeleccionado);
    return response.data.disposiciones;
  }
  if (integracionCCC) {
    const response = await server.get<{
      disposiciones: Disposiciones[];
    }>("/examen/sujetos/" + integracionCCC);
    return response.data.disposiciones;
  }
  return [];
};

export const fetchDisposicionesWithSujetos = async () => {
  const response = await server.get<{
    disposiciones: DisposicionesWithSujetos[];
  }>("/examen/disposicion");
  return response.data.disposiciones;
};

export const fetchUsuarios = async () => {
  const response = await server.get<{ usuarios: Usuarios[] }>(
    "/admin/usuarios"
  );
  return response.data.usuarios;
};

export const fetchGafiRecomendaciones = async () => {
  const response = await server.get<{
    gafiRecomendaciones: GafiRecomendacionesType[];
  }>("/examen/recomendaciones");
  return response.data.gafiRecomendaciones;
};

export const fetchResultados = async () => {
  const response = await server.get<{ resultados: Resultados[] }>(
    "/admin/resultados"
  );
  return response.data.resultados;
};
