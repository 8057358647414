import { errorHandling, messageHandling } from "./../util/functions";
import { createSlice } from "@reduxjs/toolkit";
import { server } from "../util/axios";
import { AppDispatch } from "./store";

interface AuthState {
  token: string | null;
  userId: number | null;
  authRedirectPath: string;
  curso: "admin" | number | null;
  error: string | null;
  name: string | null;
  registro: number | null;
  isAdmin: boolean;
}

export const slice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    userId: null,
    authRedirectPath: "/",
    curso: null,
    error: null,
    name: "",
    registro: null,
    isAdmin: false,
  } as AuthState,
  reducers: {
    signIn: (state, action) => {
      state.token = action.payload.idToken;
      state.userId = action.payload.localId;
      state.curso =
        action.payload.curso === "admin" ? "admin" : +action.payload.curso;
      state.name = action.payload.name;
      state.registro = action.payload.registro;
      state.error = null;
      state.isAdmin = action.payload.curso === "admin";
    },
    setId: (state, action) => {
      state.userId = action.payload;
    },
    setCurso: (state, action) => {
      state.curso = action.payload === "admin" ? "admin" : +action.payload;
    },
    redirectPath: (state, action) => {
      state.authRedirectPath = action.payload.path;
    },
    logout: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("expirationDate");
      localStorage.removeItem("userId");
      localStorage.removeItem("curso");
      localStorage.removeItem("name");
      localStorage.removeItem("registro");
      state.token = null;
      state.userId = null;
      state.error = null;
      state.curso = null;
      state.name = "";
      state.registro = null;
      state.isAdmin = false;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { redirectPath, logout, setError, signIn, setId, setCurso } =
  slice.actions;

export const checkAuthTimeout =
  (expirationTime: number) => (dispatch: AppDispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };

const saveStorage = (
  dispatch: AppDispatch,
  idToken: number,
  expiresIn: number,
  localId: number,
  curso: string,
  name: string,
  registro: number
) => {
  const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
  localStorage.setItem("token", idToken.toString());
  localStorage.setItem("expirationDate", expirationDate.toString());
  localStorage.setItem("userId", localId.toString());
  localStorage.setItem("curso", curso);
  localStorage.setItem("name", name);
  localStorage.setItem("registro", registro.toString());
  server.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
  dispatch(signIn({ idToken, localId, curso, name, registro }));

  dispatch(checkAuthTimeout(expiresIn));
};

export const login = (username: string, password: string) => {
  return async (dispatch: AppDispatch) => {
    const authData = {
      username,
      password,
    };

    try {
      const response = await server.post("/auth/login", authData);

      saveStorage(
        dispatch,
        response.data.idToken,
        response.data.expiresIn,
        response.data.localId,
        response.data.curso,
        response.data.name,
        response.data.registro
      );
    } catch (err: any) {
      if (err && "response" in err && err.response && "data" in err.response) {
        dispatch(setError(err.response.data.message));
      } else {
        errorHandling(dispatch, err);
      }
    }
  };
};
export const signup = (
  nombre: string,
  username: string,
  password: string,
  registro: number,
  curso: number
) => {
  return async (dispatch: AppDispatch) => {
    const authData = {
      username,
      password,
      name: nombre,
      registro,
      curso: curso,
    };
    const url = "/auth/signup";

    try {
      const response = await server.put(url, authData);
      messageHandling(dispatch, response.data.message);
      saveStorage(
        dispatch,
        response.data.idToken,
        response.data.expiresIn,
        response.data.localId,
        response.data.curso,
        response.data.name,
        response.data.registro
      );
    } catch (err: any) {
      if (err && "response" in err && err.response && "data" in err.response) {
        dispatch(setError(err.response.data.message));
      } else {
        errorHandling(dispatch, err);
      }
    }
  };
};

export const forgotPassword = (username: string, registroCNBV: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const url = `/auth/forgotPassword/${username}/${registroCNBV}`;
      const response = await server.get(url);
      dispatch(setError(null));
      dispatch(setId(response.data.id));
    } catch (err: any) {
      if (err && "response" in err && err.response && "data" in err.response) {
        dispatch(setError(err.response.data.message));
      } else {
        errorHandling(dispatch, err);
      }
    }
  };
};

export const newPassword = (id: number, password: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const url = `/auth/newPassword/${id}`;
      const response = await server.post(url, { password });
      messageHandling(dispatch, response.data.message);
      dispatch(logout());
    } catch (err) {
      errorHandling(dispatch, err);
    }
  };
};

export const authCheckState = () => {
  return (dispatch: AppDispatch) => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    } else {
      const tempExpirationTime = localStorage.getItem("expirationDate");
      const expirationDate = new Date(tempExpirationTime || "");
      if (expirationDate > new Date()) {
        const userId = localStorage.getItem("userId");
        const curso = localStorage.getItem("curso");
        const name = localStorage.getItem("name");
        const registro = localStorage.getItem("registro");
        server.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        dispatch(
          signIn({
            idToken: token,
            localId: userId,
            curso: curso,
            name,
            registro,
          })
        );
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      } else {
        dispatch(logout());
      }
    }
  };
};

export default slice.reducer;
