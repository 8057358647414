import { useState } from "react";
import styles from "./SignIn.module.css";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { login, setError, signup } from "../../../store/authSlice";

import { Button } from "../../UI/Button/Button";
import { Spinner } from "../../UI/Spinner/Spinner";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { Input } from "../../UI/Input/Input";
import { Select } from "../../UI/Select/Select";
import { Actions } from "../../UI/Actions/Actions";
import { fetchCursos } from "../../../util/fetchRequest";
import { ErrorMessage } from "../../UI/ErrorMessage/ErrorMessage";

const ValidationSignUp = Yup.object().shape({
  nombre: Yup.string().required("Requerido"),
  password: Yup.string()
    .min(6, "La contraseña debe de ser minimo de 6 caracteres")
    .required("Requerido"),
  username: Yup.string().required("Requerido"),
  registro: Yup.number()
    .positive("Debe de ser un numero positivo")
    .required("Porfavor de numero de registro"),
  curso: Yup.number().required("Porfavor eliga un curso"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Deben ser iguales"
  ),
});

const ValidationLogIn = Yup.object().shape({
  password: Yup.string()
    .min(6, "La contraseña debe de ser minimo de 6 caracteres")
    .required("Requerido"),
  username: Yup.string().required("Requerido"),
});

interface IFormLogin {
  username: string;
  password: string;
}
interface IFormSignUp extends IFormLogin {
  confirmPassword: string;
  nombre: string;
  registro: number;
  curso: number;
}

interface ISignUpProps {
  submitHandler: SubmitHandler<IFormSignUp>;
}
interface ILogInProps {
  submitHandler: SubmitHandler<IFormLogin>;
}

const SignUp: React.FC<ISignUpProps> = (props) => {
  const { isLoading, data: dbCursos } = useQuery("fetchCursos", fetchCursos);

  const cursos = dbCursos?.cursos.filter((c) => c.status) || [];

  const cursosOptions = cursos.map((cur) => {
    return { text: cur.descripcion, value: cur.id };
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormSignUp>({
    resolver: yupResolver(ValidationSignUp),
    defaultValues: {
      username: "",
      password: "",
      nombre: "",
    },
  });

  return (
    <form onSubmit={handleSubmit(props.submitHandler)}>
      <div className={styles.form}>
        <Input
          title="Nombre completo"
          type="text"
          errors={errors}
          {...register("nombre")}
        />
        <Input
          title="Nombre de usuario"
          type="username"
          errors={errors}
          {...register("username")}
        />
        <Input
          title="Registro CNBV"
          type="number"
          errors={errors}
          {...register("registro")}
        />
        {!isLoading ? (
          <Select
            title="Cursos"
            options={cursosOptions}
            errors={errors}
            {...register("curso")}
          />
        ) : (
          <Spinner />
        )}
        <Input
          title="Contraseña"
          type="password"
          errors={errors}
          {...register("password")}
        />
        <Input
          title="Confirmar contraseña"
          type="password"
          errors={errors}
          {...register("confirmPassword")}
        />
        <Actions style={{ justifyContent: "space-between" }}>
          <Button type="submit">Continuar</Button>
          <Link to="/forgotPassword">Olvide mi contraseña</Link>
        </Actions>
      </div>
    </form>
  );
};

const Login: React.FC<ILogInProps> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormLogin>({
    resolver: yupResolver(ValidationLogIn),
    defaultValues: { username: "", password: "" },
  });
  return (
    <form onSubmit={handleSubmit(props.submitHandler)}>
      <div className={styles.form}>
        <Input
          title="Nombre de usuario"
          type="username"
          errors={errors}
          {...register("username")}
        />
        <Input
          title="Contraseña"
          type="password"
          errors={errors}
          {...register("password")}
        />
        <Actions style={{ justifyContent: "space-between" }}>
          <Button type="submit">Continuar</Button>
          <Link to="/forgotPassword">Olvide mi contraseña</Link>
        </Actions>
      </div>
    </form>
  );
};

const SignIn: React.FC = () => {
  const { error } = useAppSelector((state) => state.auth);

  const [isSignup, setIsSignup] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const switchAuthModeHandler = () => {
    setIsSignup(!isSignup);
  };

  let errorMessage = null;
  if (error) {
    errorMessage = <ErrorMessage>{error}</ErrorMessage>;
    setTimeout(() => {
      dispatch(setError(null));
    }, 4000);
  }

  const renderSignInMethod = isSignup ? (
    <SignUp
      submitHandler={(values, event) => {
        event?.preventDefault();
        setLoading(true);
        dispatch(
          signup(
            values.nombre,
            values.username,
            values.password,
            values.registro,
            values.curso
          )
        ).then(() => {
          setLoading(false);
          setIsSignup(false);
        });
      }}
    />
  ) : (
    <Login
      submitHandler={(values, event) => {
        event?.preventDefault();
        setLoading(true);
        dispatch(login(values.username, values.password)).then(() =>
          setLoading(false)
        );
      }}
    />
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <h1 className={styles.card_title}>
          {isSignup ? "Registrar" : "Log-in"}
        </h1>

        {loading ? <Spinner /> : renderSignInMethod}
        {errorMessage}
        <Button
          color="primary"
          outline
          onClick={switchAuthModeHandler}
          disabled={loading}
        >
          Cambiar a {isSignup ? "log-in" : "registrar"}
        </Button>
      </div>
    </div>
  );
};

export default SignIn;
