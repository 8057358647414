import React from "react";
import styles from "../Navigation.module.css";
import { NavLink } from "react-router-dom";

interface NavBarItemProps {
  onClick?: () => void;
  link: string;
  target?: string;
  style?: React.CSSProperties;
  exact?: boolean;
}

export const NavBarItem: React.FC<NavBarItemProps> = (props) => {
  return (
    <li className={styles.nav_list_items}>
      <NavLink
        style={props.style}
        onClick={props.onClick}
        activeClassName={styles.nav_list_items_item___active}
        className={styles.nav_list_items_item}
        to={props.link}
        target={props.target}
        exact={props.exact}
      >
        {props.children}
      </NavLink>
    </li>
  );
};
