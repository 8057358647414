import React from "react";
import styles from "../Navigation.module.css";
import { NavBarItem } from "./NavBarItem";
import { Button } from "../../UI/Button/Button";
import { logout } from "../../../store/authSlice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { useQuery } from "react-query";
import { fetchCursos } from "../../../util/fetchRequest";
import { DropdownItem } from "./DropdownItem";

interface NavBarItemsProps {
  menuHandler: () => void;
}
export const NavBarItems: React.FC<NavBarItemsProps> = (props) => {
  const { token, curso, isAdmin } = useAppSelector((state) => state.auth);
  const isAuthenticated = token !== null;
  const dispatch = useAppDispatch();

  const { data: dbCursos } = useQuery("fetchCursos", fetchCursos);

  const detalladoCursos = dbCursos?.detalladoCursos || [];

  const detallado = detalladoCursos.filter((dc) => dc.idCurso === curso);

  const count = detallado.length;
  let renderDocumentacion = null;
  switch (count) {
    case 0:
      renderDocumentacion = null;
      break;
    case 1:
      const selDocu = detallado[0];
      renderDocumentacion = (
        <a
          className={styles.nav_list_items_item}
          target="_blank"
          href="/"
          onClick={() => {
            window.open(selDocu.link || "");
          }}
        >
          {selDocu.descripcion}
        </a>
      );
      break;
    default:
      renderDocumentacion = (
        <DropdownItem title="Documentación">
          <li
            className={styles.nav_list_items}
            style={{ flexDirection: "column" }}
          >
            {detallado.map((docu) => (
              <a
                className={styles.nav_list_items_item}
                target="_blank"
                href="/"
                onClick={() => {
                  window.open(docu.link || "");
                }}
              >
                {docu.descripcion}
              </a>
            ))}
          </li>
        </DropdownItem>
      );
      break;
  }

  return (
    <ul className={styles.nav_list}>
      {isAuthenticated ? (
        <NavBarItem exact onClick={props.menuHandler} link="/">
          Usuario
        </NavBarItem>
      ) : null}

      {renderDocumentacion}

      {isAdmin ? (
        <NavBarItem onClick={props.menuHandler} link="/administracion">
          Administración
        </NavBarItem>
      ) : null}

      {!isAuthenticated ? (
        <NavBarItem onClick={props.menuHandler} link="/auth">
          Entrar
        </NavBarItem>
      ) : (
        <Button
          onClick={() => {
            dispatch(logout());
            props.menuHandler();
          }}
        >
          Logout
        </Button>
      )}
    </ul>
  );
};
